<template>
  <div class="home">
    <x-header>用户管理</x-header>
    <x-content
      v-if="loading"
      style="height: 100%"
      :contentStyle="{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }"
    >
      <bounce-loading></bounce-loading>
    </x-content>
    <x-content
      v-else
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="待审核用户" name="tab1">
          <div class="search_warp" style="margin-left: 5px;">
            <el-row class="list-control_warp">
              <el-col :span="4">
                <el-input
                  alwaysShowClear
                  clearable
                  v-model="searchText1"
                  @keydown.enter.native="search1"
                  placeholder="输入用户名搜索"
                >
                  <template slot="suffix">
                    <i
                      @click="search1"
                      style="cursor: pointer"
                      class="el-input__icon base-icon-search"
                    ></i>
                  </template>
                </el-input>
              </el-col>
            </el-row>
          </div>
          <x-card>
            <el-table :data="datas1" stripe style="width: 100%">
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="institution" label="机构">
              </el-table-column>
              <el-table-column prop="email" label="邮箱"> </el-table-column>
              <el-table-column prop="createTime" label="申请时间"> </el-table-column>
              <el-table-column width="140" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="auditUser(scope.row.id)"
                    >审核</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                @current-change="pageChange1"
                background
                layout="total,prev, pager, next"
                unitText="名用户"
                :total="total1"
                :page-size="pageSize1"
                :current-page.sync="page1"
              ></el-pagination>
            </div>
          </x-card>
        </el-tab-pane>
        <el-tab-pane label="已审核用户" name="tab2">
          <div class="search_warp">
            <el-row class="list-control_warp" style="margin-left: 5px;">
              <el-col :span="4">
                <el-input
                  alwaysShowClear
                  clearable
                  v-model="searchText"
                  @keydown.enter.native="search"
                  placeholder="输入用户名搜索"
                >
                  <template slot="suffix">
                    <i
                      @click="search"
                      style="cursor: pointer"
                      class="el-input__icon base-icon-search"
                    ></i>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="3" style="margin-left: 15px">
                <el-select
                  v-model="searchUserRole"
                  style="width: 100%"
                  @change="search"
                  clearable
                  placeholder="请选择角色"
                >
                  <el-option
                    :value="item.id"
                    :label="item.name"
                    v-for="(item, index) in allRoles"
                    :key="'role' + index"
                  ></el-option>
                </el-select>
              </el-col>
              <div style="display: flex; justify-content: flex-end">
                <el-button
                  size="medium"
                  type="primary"
                  @click="$router.push('/manager/role')"
                  v-if="
                    hasPermission([
                      PERMISSION.ROLE_MANAGER,
                      PERMISSION.ROLE_EDIT,
                    ])
                  "
                >
                  <span style="display: inline-flex; align-items: center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      width="14"
                      height="14"
                      class="Icon"
                    >
                      <path
                        data-v-3cc980e7=""
                        d="M4.076 16.966a4.19 4.19 0 0 1 1.05-1.76l8.568-8.569a.524.524 0 0 1 .741 0l2.928 2.927a.524.524 0 0 1 0 .74l-8.568 8.57c-.49.49-1.096.852-1.761 1.051l-3.528 1.058a.394.394 0 0 1-.49-.488l1.06-3.53zM20.558 4.83c.59.59.59 1.546 0 2.136l-1.693 1.692a.503.503 0 0 1-.712 0l-2.812-2.812a.504.504 0 0 1 0-.712l1.693-1.693a1.51 1.51 0 0 1 2.135 0l1.389 1.389z"
                      ></path>
                    </svg>
                    <span>角色维护</span>
                  </span>
                </el-button>
              </div>
            </el-row>
          </div>
          <x-card>
            <el-table :data="datas" stripe style="width: 100%">
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <!--  <avatar :url="scope.row.avatar" round size="32"></avatar>-->
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="institution" label="机构">
              </el-table-column>
              <el-table-column prop="email" label="邮箱"> </el-table-column>
              <el-table-column label="角色">
                <template slot-scope="scope">
                  <template
                    v-if="!scope.row.roleList || !scope.row.roleList[0]"
                  ></template>
                  <template
                    v-else-if="
                      scope.row.roleList &&
                      scope.row.roleList[0] &&
                      scope.row.roleList[0].code != 'superadmin'
                    "
                  >
                    {{
                      (scope.row.roleList &&
                        scope.row.roleList.map((e) => e.name).join(",")) ||
                      "普通用户"
                    }}
                  </template>
                  <template v-else> -- </template>
                </template>
              </el-table-column>
              <el-table-column width="190" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="editRole(scope.row)"
                    v-if="scope.row.roleList[0].code != 'superadmin'"
                    >修改权限</el-button
                  >
                  <el-button

                  class="btnInfo"
                    size="mini"
                    @click="editIns(scope.row)"
                    v-if="hasRole([SYSTEM_ROLE.SUPER_ADMIN])"
                    >机构修改</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                @current-change="pageChange"
                background
                layout="total,prev, pager, next"
                unitText="名用户"
                :total="total"
                :page-size="pageSize"
                :current-page.sync="page"
              ></el-pagination>
            </div>
          </x-card>
        </el-tab-pane>
        <el-tab-pane label="已驳回用户" name="tab3">
          <div class="search_warp">
            <el-row class="list-control_warp" style="margin-left: 5px;">
              <el-col :span="4">
                <el-input
                  alwaysShowClear
                  clearable
                  v-model="searchText2"
                  @keydown.enter.native="search2"
                  placeholder="输入用户名搜索"
                >
                  <template slot="suffix">
                    <i
                      @click="search2"
                      style="cursor: pointer"
                      class="el-input__icon base-icon-search"
                    ></i>
                  </template>
                </el-input>
              </el-col>
            </el-row>
          </div>
          <x-card>
            <el-table :data="datas2" stripe style="width: 100%">
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="institution" label="机构">
              </el-table-column>
              <el-table-column prop="email" label="邮箱"> </el-table-column>
              <el-table-column width="100" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="auditUser(scope.row.id)"
                    >审核</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                @current-change="pageChange2"
                background
                layout="total,prev, pager, next"
                unitText="名用户"
                :total="total2"
                :page-size="pageSize2"
                :current-page.sync="page2"
              ></el-pagination>
            </div>
          </x-card>
        </el-tab-pane>
      </el-tabs>
    </x-content>
    <app-dialog
      ref="roleDialog"
      :dialogTitle="roleDialogTitle"
      originTitle
      staticDialog
      width="400px"
    >
      <el-select v-model="selectUserRole" style="width: 100%">
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="(item, index) in allRoles"
          :key="'role' + index"
        ></el-option>
      </el-select>
      <div class="save_btn_warp">
        <el-button
          type="primary"
          style="width: 50%"
          size="medium"
          @click="saveUserRole"
          >保存</el-button
        >
      </div>
    </app-dialog>
    <app-dialog
      ref="insDialog"
      :dialogTitle="insDialogTitle"
      originTitle
      staticDialog
      width="500px"
    >
    
    <el-select v-model="selectUserIns" style="width:100%;" filterable >
                        <el-option :value="item.id" :label="item.instituteName" v-for="(item,index) in insList" :key="'ins' + index"></el-option>
                   </el-select>
      <div class="save_btn_warp">
        <el-button
          type="primary"
          style="width: 200px"
          size="medium"
          @click="saveUserIns"
          >保存</el-button
        >
      </div>
    </app-dialog>
    <app-dialog  ref="userAuditDialog" dialogTitle="用户审核" originTitle staticDialog width="600px">
        <el-form >
          <el-form-item >
            <el-radio-group v-model="auditForm.auditStatus">
              <el-radio :label="1">审核通过</el-radio>
              <el-radio :label="2">审核驳回</el-radio>
            </el-radio-group>
          </el-form-item>
        <el-form-item >
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入审核意见"
            v-model="auditForm.reason">
        </el-input>
        </el-form-item>
        </el-form>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:200px;" size="medium"  @click="auditSaveUser">确定</el-button>
        </div>
      </app-dialog>
  </div>
</template>
<script>
import {
  GetAllUser,
  GetRoles,
  UpdateUserRole,
  UpdateUserIns,
  GetUserDetails,
  GetUnauditedUser,
  AuditUser,
} from "../../service/user";
import {InstituteListData} from "../../service/institute"

export default {
  name: "UserManager",
  data() {
    return {
      activeName: "tab1",
      loading: false,
      searchText: "",
      datas: [],
      page: 1,
      pageSize: 10,
      total: 0,
      searchText1: "",
      datas1: [],
      page1: 1,
      pageSize1: 10,
      total1: 0,
      searchText2: "",
      datas2: [],
      page2: 1,
      pageSize2: 10,
      total2: 0,
      editUser: {},
      allRoles: [],
      selectUserRole: "",
      searchUserRole: "",
      selectUserIns:"",
      auditForm:{},
      insList:[],

    };
  },
  created() {
    this.getUnauditedUser();
    this.getBackauditedUser();
    this.getAllUser();
    this.getRoles();
  },
  computed: {
    roleDialogTitle() {
      return `重新分配 ${this.editUser.name} 的权限`;
    },
    insDialogTitle() {
    return `重新修改 ${this.editUser.name} 的所属机构`;
    },
  },
  methods: {
    editRole(data) {
      this.editUser = data;
      this.selectUserRole = data?.roleList?.[0]?.id || "3";
      this.$refs.roleDialog.show();
    },
    saveUserRole() {
      UpdateUserRole(this.editUser.id, this.selectUserRole)
        .then((e) => {
          let data = this.APIParse(e);
          if (data) {
            this.$message.success("用户权限分配成功");
            this.$refs.roleDialog.hide();
            this.editUser = {};
            this.selectUserRole = "";
            this.getAllUser();
          } else {
            this.$message.error("用户权限分配失败，请重试");
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    getRoles() {
      GetRoles()
        .then((e) => {
          let data = this.APIParse(e);
          this.allRoles = data || [];
        })
        .catch((e) => {
          this.$message.error("角色列表加载失败");
        });
    },
    getAllUser() {
      this.loading = true;
      GetAllUser({
        current: this.page,
        size: this.pageSize,
        name: this.searchText,
        role: this.searchUserRole,
      })
        .then((e) => {
          let data = this.APIParse(e);
          this.datas = data.records || [];
          this.total = data.total || 0;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.getAllUser();
    },
    pageChange() {
      this.getAllUser();
    },
    getUnauditedUser() {
      this.loading = true;
      GetUnauditedUser({
        page: this.page1,
        size: this.pageSize1,
        text: this.searchText1,
        auditStatus:0,
      })
        .then((e) => {
          let data = this.APIParse(e);
          this.datas1 = data.records || [];
          this.total1 = data.total || 0;
          if (this.total1 == 0) {
            this.activeName = "tab2";
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search1() {
      this.page1 = 1;
      this.getUnauditedUser();
    },
    pageChange1() {
      this.getUnauditedUser();
    },
    getBackauditedUser() {
      this.loading = true;
      GetUnauditedUser({
        page: this.page2,
        size: this.pageSize2,
        text: this.searchText2,
        auditStatus:2,
      })
        .then((e) => {
          let data = this.APIParse(e);
          this.datas2 = data.records || [];
          this.total2 = data.total || 0;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search2() {
      this.page2 = 1;
      this.getBackauditedUser();
    },
    pageChange2() {
      this.getBackauditedUser();
    },
    auditUser(userId){
      this.auditForm.userId=userId;
      this.$refs.userAuditDialog.show();

    },
    auditSaveUser() {
      if(!this.auditForm.auditStatus){
        this.$message.warning("请选择审核状态");
      return
      }
      const text=this.auditForm.auditStatus==1?'通过':'驳回'
        this.$confirm('确定要'+text+'该用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
      AuditUser(this.auditForm)
        .then((e) => {
          let data = this.APIParse(e);
          if (data) {
            this.$message.success("操作成功");
            location.reload();
          }
        })
        .catch((e) => {
          this.$message.error("操作失败");
        });
    })
    },
    editIns(data) {
      this.editUser = data;
      this.selectUserIns=data.institutionId

      this.getInsListData();

      this.$refs.insDialog.show();
    },
    getInsListData(){
      InstituteListData().then(e => {
          let data = this.APIParse(e);
        this.insList = data
      }).catch(e => {
          this.$message.error('机构列表加载失败')
      })
            },
    saveUserIns() {
      UpdateUserIns(this.editUser.id, this.selectUserIns)
        .then((e) => {
          let data = this.APIParse(e);
          if (data) {
            this.$message.success("用户机构修改成功");
            this.$refs.insDialog.hide();
            this.editUser = {};
            this.selectUserIns= "";
            this.getAllUser();
          } else {
            this.$message.error("用户机构修改失败，请重试");
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    getRoles() {
      GetRoles()
        .then((e) => {
          let data = this.APIParse(e);
          this.allRoles = data || [];
        })
        .catch((e) => {
          this.$message.error("角色列表加载失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.content_warp {
  display: block;
}
.search_warp {
  width: 100%;
  margin: 16px 0px;
}
.page-warp {
  width: 100%;
  text-align: right;
  margin-top: 8px;
}

.save_btn_warp {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>