const routes = [
  // {
  //   path: '/403',
  //   name: 'NoPermission',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NoPermission.vue')
  // },
  {
    path: '/',
    name: 'Index',

    component: () => import('../views/Index.vue')
  },
  {
    path: '/loadLogin',
    name: 'LoadLogin',
    component: () => import('../views/LoadLogin.vue')
  }, 
   {
    path: '/403',
    name: 'NoPermission',
    component: () => import('../views/console/NoPermission.vue')
  },
  {
    path: '/unAudited',
    name: 'UnAudited',
    component: () => import('../views/UnAudited.vue')
  },
  {
    path: '/console',
    name: 'Console',
    component: () => import('../views/console/index.vue'),
    children: [
      {
        path: '/manager/user',
        name: 'UserManager',
        component: () => import('../views/console/UserManager.vue')
      },
      {
        path: '/manager/log',
        name: 'LogManager',
        component: () => import('../views/console/LogManager.vue')
      },
      {
        path: '/manager/role',
        name: 'RoleManager',
        component: () => import('../views/console/RoleManager.vue')
      },
      {
        path: '/manager/experiment/:groupId',
        name: 'ExperimentManage',
        component: () => import('../views/console/ExperimentManage.vue')
      },
      {
        path: '/manager/experimentSearch',
        name: 'ExperimentSearch',
        component: () => import('../views/console/ExperimentSearch.vue')
      },
      {
        path: '/manager/experimentAudit',
        name: 'ExperimentAudit',
        component: () => import('../views/console/ExperimentAudit.vue')
      },
      {
        path: '/manager/experiment/detail/:id/:isReview',
        name: 'ExperimentDetail',
        component: () => import('../views/console/ExperimentDetail.vue')
      },
      {
        path: '/manager/experiment/review/:status',
        name: 'ExperimentReview',
        component: () => import('../views/console/ExperimentReview.vue')
      },
      {
        path: '/manager/delete/experiment',
        name: 'ExperimentDelete',
        component: () => import('../views/console/ExperimentDelete.vue')
      },
      {
        path: '/manager/delete/group',
        name: 'GroupDelete',
        component: () => import('../views/console/GroupDelete.vue')
      },
      {
        path: '/manage/user/details',
        name: 'UserDetails',
        component: () => import('../views/console/UserDetails.vue')
      },

      {
        path: '/manager/experiment/share/:share',
        name: 'ExperimentShare',
        component: () => import('../views/console/ExperimentShare.vue')
      },
      {
        path: '/manager/experiment/edit/:groupId/:formTemplateId/:id/:isCopy',
        name: 'ExperimentEdit',
        component: () => import('../views/console/ExperimentEdit.vue')
      },
      {
        path: '/manager/experimentChild/edit/:groupId/:formTemplateId/:id/:isCopy/:parentId',
        name: 'ExperimentChildEdit',
        component: () => import('../views/console/ExperimentEdit.vue')
      },
      {
        path: '/',
        alias:['/manager/ExperimentManageType/1'],
        name: 'ExperimentManage',
        component: () => import('../views/console/ExperimentManageType.vue')
      },
      {
        path: '/manager/group/',
        name: 'GroupManager',
        component: () => import('../views/console/GroupManager.vue')
      },
      {
        path: '/manager/group/:type',
        name: 'GroupManager1',
        component: () => import('../views/console/GroupManager.vue')
      },
      {
        path: '/manager/auditGroup',
        name: 'AuditGroupManager',
        component: () => import('../views/console/AuditGroupManager.vue')
      },
      {
        path: '/manager/auditSearch',
        name: 'AuditSearch',
        component: () => import('../views/console/AuditSearch.vue')
      },
      {
        path: '/manager/group/detail/:groupId',
        name: 'GroupDetail',
        component: () => import('../views/console/GroupDetail.vue')
      },
      {
        path: '/manager/groupUser/:groupId',
        name: 'GroupManager',
        component: () => import('../views/console/GroupUserManager.vue')
      },
      {
        path: 'manager/groupUser/:groupId/:type',
        name: 'GroupManager',
        component: () => import('../views/console/GroupUserManager.vue')
      },
      {
        path: '/manager/template',
        name: 'TemplateManager',
        component: () => import('../views/console/TemplateManager.vue')
      },
      {
        path: '/manager/template/edit',
        name: 'TemplateEdit',
        component: () => import('../views/console/TemplateEdit.vue')
      },
      {
        path: '/manager/note/:groupId',
        name: 'NoteManager',
        component: () => import('../views/console/NoteManager.vue')
      },
      {
        path: '403',
        name: 'NoPermissionConsole',
        component: () => import('../views/console/NoPermission.vue')
      },
      {
        path: '/manager/projectType',
        name: 'ProjectTypeManage',
        component: () => import('../views/console/ProjectTypeManager.vue')
      },
      {
        path: '/manager/domainConfig',
        name: 'DomainConfigManage',
        component: () => import('../views/console/DomainConfigManager.vue')
      },
      {
        path: '/manager/applyUse',
        name: 'ApplyUseManage',
        component: () => import('../views/console/ApplyUseManager.vue')
      },
      {
        path: '/manager/institute',
        name: 'InstituteManager',
        component: () => import('../views/console/InstituteManager.vue')
      },
      {
        path: '/manager/experimentType/:groupId',
        name: 'ExperimentManageType',
        component: () => import('../views/console/ExperimentManageType.vue')
      },
      {
        path: '/manager/researchTools',
        name: 'ResearchTools',
        component: () => import('../views/ResearchTools/ResearchTools.vue')
      },
      {
        path: '/manager/researchTools/latexOcr',
        name: 'LatexOcr',
        component: () => import('../views/ResearchTools/LatexOcr.vue')
      },
      {
        path: '/manager/researchTools/chemistryOcr',
        name: 'LatexOcr',
        component: () => import('../views/ResearchTools/ChemistryOcr.vue')
      },
      {
        path: '/manager/researchTools/speechRecognition',
        name: 'LatexOcr',
        component: () => import('../views/ResearchTools/SpeechRecognition.vue')
      },
    ]
  }
]

export default routes
