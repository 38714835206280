<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { GetRoles } from "./service/user";
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    //获取角色信息填充角色没有权限访问的页面
    //先注释掉，使用默认的，后续需要再行解开
    // GetRoles().then(e => {
    //   let data = this.APIParse(e);
    //   data.forEach(element => {
    //       this.roleNotPage[element.code] = element?.notPermissionPage?.split(';') || []
    //   });
    // })
  },
};
</script>

<style lang="scss">
@import "./components/base/ui/style/index.scss";
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
}

/*定义滚动条轨道 内阴影+圆角*/
*::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 9px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: transparent;
  // background-color: #F5F5F5; //transparent
}

/*定义滑块 内阴影+圆角*/
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
  // -webkit-box-shadow: inset 0 0 9px rgba(0,0,0,.3);
  //background-color: #bfbfbf;
}
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.85);
}
#app {
  width: 100%;
  height: 100%;
}
input {
  outline: none !important;
}
.mySign {
  border: 1px dashed #000;
}

//以下为样式重写
.base-input__inner,
.base-textarea__inner {
  background-image: linear-gradient(0deg, #fff, #f3f5f8);
  border: 1px solid #bfc3de;
  border-radius: 4px;
  transition: border-color 0.3s;
  padding-left: 10px;
  margin-left: -5px;
  line-height: 36px;
}
.base-input__inner {
  height: 36px;
}
.base-input__suffix {
  right: 5px;
}
.base-input.is-rippled:before,
.base-input.is-rippled:after,
.base-textarea.is-rippled:before,
.base-textarea.is-rippled:after {
  height: 0px;
}
.base-input__icon {
  line-height: 36px;
}
.base-form-item__content,
.base-form-item__label,
.base-input__placeholder {
  line-height: 36px !important;
}
.base-date-editor .base-range-input {
  border-bottom: 0px;
}
.base-form-item__label {
  line-height: 30px !important;
  margin-bottom: 10px;
}
.base-input__placeholder.is-active {
  height: 0px;
}
//获取邮箱验证码样式
.emailCodeBtn {
  line-height: 36px;
  height: 36px;
  background-color: transparent;
}
</style>