<template>
  <div class="home">
    <x-header back>个人中心</x-header>
     <x-content  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
    <x-card>
      <el-row :gutter="22" style="margin-top: 20px">
        <el-col :span="12" >
          <el-divider><h2 class="myCenterTitle">个人信息</h2></el-divider>
          <div class="userInfoData">
            <p><span class="label">姓名</span><font>:</font>{{user.name}}</p>
            <p v-if="user.institution"><span class="label">机构</span><font>:</font>{{user.institution}}</p>
            <p v-if="user.email"><span class="label">邮箱</span><font>:</font>{{user.email}}</p>
            <p v-if="user.mobile"><span class="label">手机</span><font>:</font>{{user.mobile}}</p>
            <p v-if="roleName"><span class="label">角色</span><font>:</font>{{roleName}}</p>
            <p v-if="roleName"><span class="label">科创链用户名</span><font>:</font>{{user.chainUserName}}</p>
          </div>
        </el-col>
        <el-col :span="12">
        
          <el-divider><h2 class="myCenterTitle">电子签名</h2></el-divider>
          <template v-if="userSignature.signature">
            <div style="display: flex;justify-content: center;">
              <img :src="userSignature.signature"   class="mySign"/>
            </div>
            <div class="save_btn_warp">
            <el-button type="warning"  style="width:100px;" size="small" @click="toUpdateSign" >更新签名</el-button>
            </div>
          </template>
          <template v-else>
            <div style="display: flex;justify-content: center;">
              <el-form :model="userSignature" ref="userSignatureForm" >
              <vue-esign
                  ref="esign"
                  class="mySign"
                  :width="600"
                  :height="300"
                  :isCrop="false"
                  :lineWidth="6"
                  lineColor="#000000"
                  bgColor.sync="bgColor"

              />
                <br>
              <el-form-item >
                <el-input show-password type="number" v-model="userSignature.password" @clear="userSignature.password = ''" placeholder="请输入签名密码，密码格式为6位数字，下次输入密码即可完成电子签名"></el-input>
              </el-form-item>
              </el-form>
            </div>
            <div class="save_btn_warp">
              <el-button type="warning" style="width:100px;" size="small"  @click="resetSign">清空签名</el-button>
              <el-button type="primary" style="width:100px;" size="small"  @click="saveSignImg">确定</el-button>
              <el-button type="info" style="width:100px;" size="small"  @click="goBack">取消</el-button>
            </div>
          </template>
        </el-col>
      </el-row>
    </x-card>
  </x-content>
    <app-dialog  ref="userSignatureDialog" dialogTitle="验证签名密码" originTitle staticDialog width="600px">
      <div>
        <el-form >
          <el-form-item  v-model="password">
            <el-input  v-model="password" show-password placeholder="请输入签名密码" style="width: 82%;"></el-input>
            <el-link type="primary" :underline="false" style=" margin-left: 20px;" @click="findPwd"><i class="el-icon-question" style="margin-right: 5px;"></i>忘记密码</el-link>
          </el-form-item>
          <div class="save_btn_warp">
            <el-button type="primary" @click="verify" style="width:200px;" size="medium" :loading="saveLoading">确定</el-button>
          </div>
        </el-form>
      </div>
    </app-dialog>
    <app-dialog  ref="findPwdDialog" dialogTitle="忘记密码" originTitle staticDialog width="600px">
      <div>
        <el-form >
          <el-form-item  >
            <el-input  v-model="emailCode" placeholder="请输入6位邮箱验证码" style=""></el-input>
            <el-button class="emailCodeBtn" :disabled="countdown > 0" @click="sendVerificationCode" size="mini" style="position: absolute;right: 0;z-index: 1;">
              {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>

          </el-form-item>
          <div class="save_btn_warp">
            <el-button type="primary" @click="checkEmailCode" style="width:200px;" size="medium" :loading="saveLoading1">确定</el-button>
          </div>
        </el-form>
      </div>
    </app-dialog>
  </div>
</template>
<script>
import {GetUserDetails,UpdateUser} from "@/service/user";
import {SignatureSave,GetSignature, Verify,SendEmailCode,CheckEmailCode} from "@/service/userSignature"

export default {
  name: 'UserDetails',
  data() {
    return {
      user: {},
      roleName:'',
      rules:{
        name:[{required:true,message:'请输入姓名',trigger:'blur'}],
        institution:[{required:true,message:'请输入机构',trigger:'blur'}],
        email:[{required:true,message:'请输入邮箱',trigger:'blur'}],
      },
      userSignature:{
        signature: null,
        password: ''
      },
      password:'',
      emailCode:'',
      saveLoading:false,
      saveLoading1:false,
      countdown: 0, // 倒计时时间
      isDisabled: false, // 按钮是否禁用
    }
  },
  created() {
    //this.getUser();
    this.user=this.GetStorage(this.STORAGE.USER)
    let roleList=this.GetStorage(this.STORAGE.ROLE)
    if(roleList.length>0){
      let roleCode=roleList[0];
      this.roleName=this.SYSTEM_ROLE_NAME[roleCode];
    }
    this.getUserSignature();
  },
  computed: {
    buttonLabel() {
      return this.countdown > 0 ? `${this.countdown}秒后重新获取` : '获取验证码';
    }
  },
  methods: {
    getUserSignature() {
      GetSignature().then(e => {
        if(e.data){
          this.userSignature = e.data;
        }
      }).catch(e => {
        this.$message.error(e.message)
      }).finally(e => {
      })
    },
    /**getUser() {
      GetUserDetails().then(e => {
        this.user = e.data;
      }).catch(e => {
        this.$message.error(e.message)
      }).finally(e => {

      })
    },*/
    submitForm() {
      this.$refs.userForm.validate(valid => {
        if (valid) {
          UpdateUser(this.user)
              .then(() => {
                this.$message.success('个人信息修改成功')
              })
              .catch(() => {
                this.$message.error('个人信息修改失败，请检查您的网络或重试')
              })
        } else {
          this.$message.warning('请完善个人信息表单信息')
        }
      })
    },
    goBack() {
      this.getUserSignature();
    },
    resetSign(){
      this.$refs.esign.reset();
    },
    toUpdateSign(){
      this.$refs['userSignatureDialog'].show()

    },
    verify(){
      Verify(this.password).then(e => {
        let data = this.APIParse(e);
        if (data) {
          this.$refs['userSignatureDialog'].hide()
          this.password=''
          this.showSignForm()
        }else{
          this.$message.error("签名密码输入错误，请检查")
          this.password='';
        }
      })
      },
    showSignForm() {
      this.userSignature.signature = null;
      this.userSignature.password = '';
    },
    saveSignImg(){
      if(!this.userSignature.password){
        this.$message({
          type: "warning",
          message: "请输入签名密码",
        });
        return
      }
      var patten =/^\d{6}$/;
      if (!patten.test(this.userSignature.password)) {
        this.$message({
          type: "warning",
          message: "请输入正确的密码格式",
        });
        return false;
      }
      this.$refs.esign
          .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
          .then((res) => {
            // 构造 FormData，并将签名图片对象和签名密码作为字段传入
            this.signImg = res;
           // this.$refs['addDialog'].hide();
        // this.param.sign_img = res;a
            this.userSignature.signature=res
            if(!this.userSignature.signature){
              this.$message({
                type: "warning",
                message: "您还没有签名",
              });
              return
            }
            SignatureSave(this.userSignature).then(e => {
              if(e.code ==-1){
                this.$message.error(e.msg)
                return;
              }
              let data = this.APIParse(e)
              this.$message.success("签名保存成功")
            })
          })
          .catch((err) => {
            // 画布没有签字时会执行这里提示一下
            this.$message({
              type: "warning",
              message: "您还没有签名",
            });
          });

    },
    findPwd(){
      this.$refs['userSignatureDialog'].hide()
      this.$refs['findPwdDialog'].show()
      this.password=''
      this.emailCode=''
    },
    sendVerificationCode() {
      SendEmailCode().then(e => {
        if(e.code ==-1){
          this.$message.error(e.msg)
          this.countdown=0;
          return;
        }
        let data = this.APIParse(e);
        if (data) {
          this.$message.success(e.msg)
          this.startCountdown();
        }
      })      
    },
    startCountdown() {
         // 开始倒计时
      this.countdown = 60; // 假设倒计时60秒
      const intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    },
    checkEmailCode() {
      if(!this.emailCode){
        this.$message.warning("请输入6位邮箱验证码")
      }
      var patten =/^\d{6}$/;
      if (!patten.test(this.emailCode)) {
        this.$message({
          type: "warning",
          message: "邮箱验证码格式不对，请检查",
        });
        return false;
      }
      CheckEmailCode(this.emailCode).then(e => {
        if(e.code ==-1){
          this.$message.error(e.msg)
          this.countdown=0;
          this.this.emailCode="";
          return;
          }
        let data = this.APIParse(e);
        if (data) {
          this.$refs['findPwdDialog'].hide()
          this.showSignForm()
        }
      })      
    },
  }
}
</script>
<style lang="scss" scoped>
.userInfoData{
  margin: 0px 20px;
}
.userInfoData p font{
    padding: 0 15px 0 0;
    color: #999;
}
.userInfoData .label{
    //width: 70px;
    font-size: 14px;
    color: #999;
    line-height: 40px;
    padding: 0 5px 0 0;
    display: inline-block;
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
}
.mySign {
  border: 1px dashed #8590a6;
}
.save_btn_warp{width: 100%;margin: 30px 0px;display: flex;align-items: center;justify-content: center;}
.myCenterTitle{
  font-size:16px;
  font-weight: bold;
  text-align: center;
  color:#121212;
}
.base-divider{
  margin-bottom: 35px;
}
.el-button.is-disabled {
  background-color: #cccccc;
  border-color: #cccccc;
}
</style>
