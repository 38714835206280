<template>
	<div class="XKetcher__warp">
		<div v-if="showOcrInfo" style="margin-bottom: 5px; vertical-align: top;color: #777;">如需要化学结构式图片识别，可点击 
			<el-link type="info blueLink" href="/manager/researchTools/chemistryOcr" target="_blank" style="color:#409eff">
                  <img src="/static/img/chemistryOcr.png" style="vertical-align: text-bottom;"/>&nbsp;化学结构式识别</el-link>
			 上传识别后复制识别结果在编辑器中进行 Ctrl+V 操作粘贴。</div>
		<iframe ref="ketcherFrame" src="/static/ketcher/index.html" ></iframe>
	</div>
</template>

<script>
	export default {
		name: "XKetcher",
		data(){
			return {
				data:{
					mol:'',
					png:''
				},
				isMeUpdate:false,
				myTime:null,
				iframeLoaded:false,
				showOcrInfo:true
			}
		},
		props:{
			value:{
				type:Object,
				default:() => {
					return {
						mol:'',
						png:''
,					}
				}
			},
			showInfo:{
				type:Boolean,
				default:true
			}
		},
		watch:{
			value:{
				handler:function (newVal){
					if(newVal){
						// if(!this.isMeUpdate){
							this.setData(newVal.mol)
							this.$set(this,'data',newVal)
							this.isMeUpdate = false
						// }
					}
				},
				immediate:true,
				deep:true
			}
		},
		mounted() {
			this.showOcrInfo=this.showInfo
			this.myTime = setInterval(() => {
				this.getData();
			},1000)
		},
		unmounted(){
			if(this.myTime){
				clearInterval(this.myTime)
			}
		},
		methods:{
			getData(){
				if(this.$refs.ketcherFrame&&this.$refs.ketcherFrame.contentWindow&&this.$refs.ketcherFrame.contentWindow.ketcher){
					this.$refs.ketcherFrame.contentWindow.ketcher.getMolfile().then(mol => {
						if(this.data.mol != mol){
							this.$refs.ketcherFrame.contentWindow.ketcher.generateImage(mol).then(png => {
								this.blobToDataURI(png,(base64) => {
									this.$set(this,'data',{
										mol:mol,
										png:base64
									})
									this.isMeUpdate = true;
									this.$emit('input',this.data)
								})
							})
						}
					})
				}
			},
			blobToDataURI(blob, callback) {
				var reader = new FileReader();
				reader.onload = function (e) {
					callback(e.target.result);
				}
				reader.readAsDataURL(blob);
			},
			setData(mol){
				if(mol){
					if(this.iframeLoaded){
						this.$refs.ketcherFrame.contentWindow.ketcher.setMolecule(mol)
					}else{
						let _this = this;
						this.$nextTick(() => {
							this.$refs.ketcherFrame.onload = function(){
								_this.iframeLoaded = true;
								setTimeout(() => {
									_this.$refs.ketcherFrame.contentWindow.ketcher.setMolecule(mol)
								}, 50);
							}
						})
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.XKetcher__warp{
		width: 100%;height: 100%;
		iframe{width: 100%;height: 100%;border: none;;min-height: 640px;}
	}
</style>