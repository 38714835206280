<template>
  <div class="App__root">
    <div class="Container">
      <div class="Header">
        <header class="AppHeader">
          <div class="AppHeader__inner">
            <!-- <el-button type="primary" class="AppHeader__menuButton" icon="base-icon-menu" @click="menuCollapse = !menuCollapse"></el-button> -->
            <div class="AppHeader__logo" >
              <a href="/console">
              <img src="/static/img/logo.png" style="width: 180px;margin-top: 10px;" /> 
              </a>
            </div>
            <div class="AppHeader__domainTitle" v-if="currInsTitle">{{ currInsTitle }}</div>

            <div class="AppHeader__tool">
              <el-link type="warning" href="/manager/researchTools/latexOcr" target="_blank">
                <img src="/static/img/latexOcr.png"/>公式识别</el-link>
                <el-link type="info blueLink" href="/manager/researchTools/chemistryOcr" target="_blank" style="color:#409eff">
                  <img src="/static/img/chemistryOcr.png"/>化学结构式识别</el-link>
                 <el-link type="danger" href="/manager/researchTools/speechRecognition" target="_blank">
                  <img src="/static/img/speechRecognition.png"/>语音听写</el-link>
                <el-link type="success" href="https://www.stpaper.cn/microapp/widget/tools/database" target="_blank">
                <img src="/static/img/database.png"/>文献获取</el-link>
       
                
              <!--
                     <el-link type="info" href="https://paper.iflytek.com/" target="_blank" style="color:#f0f0f0">
                <img src="/static/img/xinghuo.svg" style="height: 22px;width: auto;" />星火科研助手</el-link>
                 <el-link type="warning" href="https://www.stpaper.cn/microapp/widget/tools/business" target="_blank">
                <img src="/static/img/business.png" />科研差旅</el-link>
                -->

                <a  href="/manager/researchTools" target="_blank" style="vertical-align: bottom;">更多 <i class="el-icon-d-arrow-right"></i></a>

            </div>
            <div class="AppHeader__userInfo">
               
              <el-button style="background: none;margin-right:25px"  size="medium" type="primary"  @click="$router.push('/manager/experimentSearch')"  v-if="hasPermission([PERMISSION.EXPERIMENT_RETRIEVAL])">
              <span><img src="/static/img/search1.png" style="vertical-align: sub;margin-right: 2px;" /> 实验检索</span>
              </el-button>
              <!--
              <el-button style="background: none;" size="medium" type="primary" @click="$message.info('建设开发中')" v-if="hasPermission([PERMISSION.EXPERIMENT_RETRIEVAL])" >
                <span><img src="/static/img/search.png" style="vertical-align: sub;margin-right: 2px;" /> 高级检索</span>
              </el-button>
              -->
              <el-button style="background-color: #929fcb;margin-right:25px" type="primary" size="medium"  @click="openDialog"  v-if="hasPermission([PERMISSION.EXPERIMENT_CREATE])">
                <span><img src="/static/img/add.png" style="vertical-align: sub;margin-right: 2px;" /> 新建实验</span>
              </el-button>
              <svg class="Icon" viewBox="0 0 1127 1024" width="24" height="24"><path d="M612.189928 1023.535885c282.889209 0 512-229.110791 512-512s-229.847482-511.263309-512-511.263309-467.798561 232.057554-512 512c-16.207194 100.926619-49.358273 271.838849-100.189928 511.263309h612.189928z" fill="rgba(225,225,255,0.9)"></path><path d="M956.22446 532.899914c4.420144 2.946763 8.840288 5.156835 13.997123 8.103597 5.893525 2.946763 11.05036 4.420144 16.207194 4.420144 5.156835 0 11.05036-5.156835 19.153957-14.733813l8.840288 7.366906c-9.576978 13.260432-18.417266 19.153957-27.994245 19.153957-5.893525 0-12.523741-1.473381-18.417266-4.420144-4.420144-2.946763-8.840288-5.156835-13.260432-8.103597-5.156835-2.946763-10.313669-4.420144-14.733813-4.420144-5.156835 0-11.78705 5.156835-19.153957 14.733813l-8.840287-7.366906c8.840288-13.260432 18.417266-19.153957 27.994244-19.153957 4.420144 0 10.313669 1.473381 16.207194 4.420144zM251.948201 289.055309h57.461871v218.060432h290.992806v-218.060432h57.46187v501.686331h-57.46187v-234.267626h-290.256115v234.267626h-57.461871v-501.686331z m546.624461 1.473382c11.78705 0 22.100719 3.683453 30.204316 11.78705 8.103597 7.366906 12.523741 16.943885 12.523741 29.467626 0 11.78705-4.420144 22.100719-12.523741 30.204316-8.103597 7.366906-18.417266 11.78705-30.204316 11.787051-11.78705 0-22.100719-4.420144-30.204317-11.787051-8.103597-8.103597-11.78705-18.417266-11.78705-30.204316 0-12.523741 3.683453-22.83741 11.78705-29.467626 8.103597-8.103597 18.417266-11.78705 30.204317-11.78705z m-27.994245 137.02446h55.988489v363.188489h-55.988489v-363.188489z" fill="#0066FF"></path></svg>
              <span class="AppHeader__userInfoText">{{user.name }}，欢迎回来</span>
              <el-dropdown style="height: 28px" v-if="user" placement="bottom" @command="handleCommand">
                <button type="button" class="Button Button--plain">
                  <img v-if="user.avatar" :src="user.avatar" style="width: 30px;border-radius: 50%;"/>
                  <svg v-else t="1686901104911" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3929" width="30" height="30"><path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0zM213.333 832A298.667 298.667 0 0 1 512 533.333a170.667 170.667 0 1 1 170.667-170.666A170.667 170.667 0 0 1 512 533.333 298.667 298.667 0 0 1 810.667 832z" fill="#e7f1ff" p-id="3930" data-spm-anchor-id="a313x.7781069.0.i7" class="selected"></path></svg> </button>
                </button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="/manage/user/details">个人中心</el-dropdown-item>
                  <el-dropdown-item command="logout" icon="base-icon-switch-button">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </header>
      </div>
      <div class="Container is-horizontal Container--x">
        <!-- -->
        <div class="Aside" :style="{width: menuCollapse ? '64px':'160px'}" >
          <el-menu class="AppMenu" :default-active="defaultActive" :collapse="menuCollapse" router>
                <el-submenu index="1"  v-if="hasPermission([PERMISSION.EXPERIMENT_MANAGER])">
              <template slot="title">
              <i class="el-icon-notebook-2 iconfont"></i>
                <span slot="title">我的实验</span>
              </template>
              <el-menu-item index="/manager/experimentType/1">
                <span slot="title">全部实验</span>
              </el-menu-item>
              <el-menu-item :index="'/manager/experimentType/'+UNSORTED_ID">
                <span slot="title">未分类实验</span>
              </el-menu-item>
              <el-menu-item :index="'/manager/experimentType/'+item.id" :key="'projectItem'+index" v-for="(item,index) in (expandFlag?projectList:projectList.slice(0,5))">
                 <el-tooltip :content="item.groupName" placement="right" >
                  <span >{{item.groupName}}</span>
                </el-tooltip>
              </el-menu-item>
              <div @click="expandFlag=!expandFlag" class="expandItem" v-if="projectList.length>5">
                <i v-if="!expandFlag" class="el-icon-caret-bottom"></i>
                <i v-if="expandFlag" class="el-icon-caret-top"></i>
              </div>
            </el-submenu>
            <el-submenu index="2" v-if="hasPermission([PERMISSION.GROUP_MENU])">
               <template slot="title">
              <i class="iconfont el-icon-folder-opened"></i>
                <span slot="title">项目管理</span>
              </template>
              <el-menu-item index="/manager/group" v-if="hasPermission([PERMISSION.GROUP_OTHER])">
                <span slot="title">全部项目</span>
              </el-menu-item>
              <el-menu-item :index="'/manager/group/'+'1,'+GROUP_ROLE.MANAGER" v-if="hasPermission([PERMISSION.GROUP_OTHER])" >
                <span slot="title">我管理的</span>
              </el-menu-item>
              <el-menu-item :index="'/manager/group/'+GROUP_ROLE.USER" v-if="hasPermission([PERMISSION.GROUP_OTHER])">
                <span slot="title">我加入的</span>
              </el-menu-item>
              <el-menu-item :index="'/manager/group/99'" v-if="hasRole([SYSTEM_ROLE.MANAGER])&&hasPermission([PERMISSION.GROUP_FILE])" >
                <span slot="title">已归档项目</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3" v-if="hasPermission([PERMISSION.EXPERIMENT_CHECK])">
              <template slot="title">
                <i class="el-icon-circle-check elIcon"></i>
                <span slot="title">复核</span>
              </template>
              <el-menu-item index="/manager/experiment/review/0">
                <span slot="title">
                    待复核 <sup v-if="tobeReviewedCount>0" class="supTitle">•</sup>
                </span>
              </el-menu-item>
              <el-menu-item index="/manager/experiment/review/1">
                <span slot="title">历史复核</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="4" v-if="hasPermission([PERMISSION.EXPERIMENT_SHARE])">
               <template slot="title">
                <i class="el-icon-share elIcon"></i>
                <span slot="title">分享</span>
              </template>
              <el-menu-item index="/manager/experiment/share/0">
                <span slot="title">我分享的</span>
              </el-menu-item>
              <el-menu-item index="/manager/experiment/share/1">
                <span slot="title">分享给我的</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="5" v-if="hasPermission([PERMISSION.ROLE_RECYCLE])">
               <template slot="title" >
                <i class="el-icon-delete elIcon"></i>
                <span slot="title">回收站</span>
              </template>
              <el-menu-item index="/manager/delete/experiment">
                <span slot="title">实验</span>
              </el-menu-item>
              <el-menu-item index="/manager/delete/group">
                <span slot="title">项目</span>
              </el-menu-item>
            </el-submenu>

            <template  v-if="!hasRole([SYSTEM_ROLE.MANAGER])&&!hasRole([SYSTEM_ROLE.ADMIN])">
            <el-submenu index="6" 
              v-if="hasPermission([PERMISSION.USER_TEMPLATE,PERMISSION.INSTITUTE_MANAGER,PERMISSION.AUDITUSE_MANAGER, PERMISSION.USER_MANAGER,PERMISSION.DOMAIN_CONFIG,PERMISSION.LOG_MANAGER])">
              <template slot="title">
                <i class="el-icon-setting elIcon"></i>
                <span slot="title">系统管理</span>
              </template>
              <el-menu-item index="/manager/template"  v-if="hasPermission([PERMISSION.USER_TEMPLATE])">
              <span slot="title">模板管理</span>
            </el-menu-item>
              <el-menu-item index="/manager/institute" v-if="hasPermission([PERMISSION.INSTITUTE_MANAGER])">
                <span slot="title">研究所管理</span>
              </el-menu-item>
               <el-menu-item index="/manager/user"  v-if="hasPermission([PERMISSION.USER_MANAGER])">
                <span slot="title">用户管理</span>
              </el-menu-item>
              <!-- 
                 <el-menu-item index="/manager/projectType"  v-if="hasPermission([PERMISSION.GROUP_MENU])">
                <span slot="title">实验分类</span>
              </el-menu-item>
              -->
              <el-menu-item index="/manager/domainConfig"  v-if="hasPermission([PERMISSION.DOMAIN_CONFIG])">
                <span slot="title">子平台管理</span>
              </el-menu-item>
              <el-menu-item index="/manager/applyUse"  v-if="hasPermission([PERMISSION.AUDITUSE_MANAGER])">
                <span slot="title">团队预约管理</span>
              </el-menu-item>
              <el-menu-item index="/manager/log"  v-if="hasPermission([PERMISSION.LOG_MANAGER])">
                <span slot="title">日志管理</span>
              </el-menu-item>
             
            </el-submenu>
            </template>
             <!-- 审计员 -->
            <el-menu-item index="/manager/experimentAudit" v-if="hasPermission([PERMISSION.EXPERIMENT_AUDIT])">
              <i class=" el-icon-receiving iconfont" ></i>
              <span slot="title">首页</span>
            </el-menu-item>
         <el-menu-item index="/manager/auditGroup" v-if="hasPermission([PERMISSION.AUDIT_GROUP])">
              <i class="iconfont el-icon-folder-opened"></i>
              <span slot="title">项目浏览</span>
            </el-menu-item>
            <el-menu-item index="/manager/auditSearch" v-if="hasPermission([PERMISSION.AUDIT_SEARCH])">
              <i class="iconfont el-icon-notebook-1"></i>
              <span slot="title">审计追踪</span>
            </el-menu-item>

            <!-- 研究所管理员 -->
            <template  v-if="hasRole([SYSTEM_ROLE.MANAGER])">
              <!-- 
            <el-menu-item index="/manager/group/99" v-if="hasPermission([PERMISSION.GROUP_FILE])">
              <i class="el-icon-folder-checked iconfont" ></i>
                <span slot="title">已归档项目</span>
              </el-menu-item>
              -->
              <el-menu-item index="/manager/template"  v-if="hasPermission([PERMISSION.USER_TEMPLATE])">
              <i class="el-icon-s-order iconfont" ></i>
                <span slot="title">模板管理</span>
              </el-menu-item> 
              <el-menu-item index="/manager/user"  v-if="hasPermission([PERMISSION.USER_MANAGER])">
              <i class="el-icon-user iconfont" ></i>
                <span slot="title">用户管理</span>
              </el-menu-item>
          </template>
            <!-- 系统管理员 -->
          <template  v-if="hasRole([SYSTEM_ROLE.ADMIN])">
              <el-menu-item index="/manager/template"  v-if="hasPermission([PERMISSION.USER_TEMPLATE])">
              <i class="el-icon-s-order iconfont" ></i>
                <span slot="title">模板管理</span>
              </el-menu-item> 
          </template>
          </el-menu>

 



          <div class="Menu__control" @click="menuCollapse = !menuCollapse">
            <i v-if="menuCollapse" class="base-icon-d-arrow-right"></i>
            <i v-if="!menuCollapse" class="base-icon-d-arrow-left"></i>
          </div>
        </div>
        <div class="Main">
          <div class="Content">
            <router-view> </router-view>
          </div>
        </div>
      </div>
    </div>
    <app-dialog  ref="selectProjectDialog" dialogTitle="项目选择" originTitle staticDialog width="800px">
      <el-row  :gutter="10">
        <el-col :span="6" v-for="(item,key) in projectData"  :key="item.id" style="margin-bottom:10px">
            <div class="thumbnail" @click="goExperimentEdit(item)">
              <p >{{item.projectCode}}</p>
              <h4> {{item.groupName}}</h4>
              <p class="hideOne">{{item.groupManagerList?item.groupManagerList.join(","):""}}</p>
            </div>
        </el-col>
          <el-col :span="6" style="margin-bottom:10px">
            <div class="thumbnail"  @click="goToAddProject()" style="background-color: #eef3ff;border:1px solid #eef3ff">
              <div style="font-size: 20px;line-height: 100px;color:#2F3A91" >
                <i class="base-icon-plus" style="font-weight: bold;"></i>
                <span style="font-size: 18px;"> 新增项目</span>
              </div>
            </div>
        </el-col>
      </el-row>
</app-dialog>

  </div>
</template>

<script>
import {GetMyProjectData,GetMyProjectList} from "@/service/user"
import {GetTobeReviewedCount} from "@/service/experiment"
import{GetDetailByInsId} from "@/service/domainConfig"
export default {
  name: 'Index',
  data () {
    return {
      menuCollapse: false,
      boxVisible: false,
      form:{},
      rules:{
       project:[{required:true,message:'请选择项目',trigger:'change'}],
      },
      projectData:[],
      projectList:[],
      currRole:'',
      expandFlag:false,
      tobeReviewedCount:0,
      currInsTitle:''
    }
  },
  computed: {
    defaultActive () {
		if(this.$route.path == '/'){
      //如果是审计 跳转到实验审计
      if(this.hasRole([this.SYSTEM_ROLE.SHENJI])){
        return '/manager/experimentAudit'
        //如果是研究所管理员 跳转到已归档项目
      }else if(this.hasRole([this.SYSTEM_ROLE.MANAGER])){
			  return '/manager/group/99'
      }else if(this.hasRole([this.SYSTEM_ROLE.ADMIN])){
        return '/manager/template'
      }else{
			  return '/manager/experiment/1'
      }
		}
      return this.$route.path
    }
  },
  created(){
    this.$root.$on("loadProject",() => {
      this.getMyProjectData();
    })
  },
  mounted () {
      //获取当前用户角色
      this.setDocumentTitle('LabLink科学实验记录本')
      //判断审核状态
      if(this.user.auditStatus!=1){
            this.$router.push('/unAudited');
      }
       this.getMyProjectData();
      this.getMyProjectList();
      this.getTobeReviewedCount();
      let curruser=this.GetStorage(this.STORAGE.USER)
      let serveName=curruser.serveName;
      let domain=window.location.hostname
      let currInstitutionId=curruser.currInstitutionId;
      //获取当前用户使用机构，获取机构名
      if(currInstitutionId){
        this.getCurrIns(currInstitutionId)
      }

       if(this.$route.path=='/'||this.$route.path=='/console'){
          if(this.hasRole([this.SYSTEM_ROLE.SHENJI])){
            //判断当前域名是否是自己机构域名，不是就跳转到正确的
            if(serveName!=domain){
              this.$message.error("当前访问地址错误，将自动为您跳转")
              setTimeout(() => {
                location.href="https://"+serveName;
              }, 3000);
            }
            this.$router.push({path:'/manager/experimentAudit'})
              //如果是研究所管理员 跳转到已归档项目
            }else if(this.hasRole([this.SYSTEM_ROLE.MANAGER])){
            //判断当前域名是否是自己机构域名，不是就跳转到正确的
            if(serveName!=domain){sss
              this.$message.error("当前访问地址错误，将自动为您跳转")
              setTimeout(() => {
                location.href="https://"+serveName;
              }, 3000);
            }
              this.$router.push({path:'/manager/group/99'})
            }else if(this.hasRole([this.SYSTEM_ROLE.ADMIN])){
              this.$router.push({path:'/manager/template'})
            }else{
             //this.$router.push({path:'/manager/experiment/1'})
              return this.$route.path
            }
       }
  },
  methods: {
    handleCommand (command) {
      if (command === 'logout') {
        this.outLogin();
      }else if(command === 'index'){
          this.$router.push('/')
      }else if(command === '/manage/user/details'){
        this.$router.push('/manage/user/details')
      }
    },
    getCurrIns(institutionId){
      GetDetailByInsId(institutionId).then(e => {
              let data = this.APIParse(e);
              if(data){
                this.currInsTitle=data;
              }
          })
    },
        getMyProjectList(){
          GetMyProjectList().then(e => {
              let data = this.APIParse(e);
              this.projectList = data;
          })
      },
    getMyProjectData(){
          GetMyProjectData().then(e => {
              let data = this.APIParse(e);
              this.projectData = data;
          })
      },
      openDialog(){
        this.form.topicHead = this.user.name
        this.$refs['selectProjectDialog'].show()
        /**if(this.projectData.length==0){
            this.$message.warning("未查询到项目数据")
            return
        }else if(this.projectData.length==1){
            this.$router.replace('/manager/experiment/edit/'+this.projectData[0].id+'/'+this.projectData[0].formTemplateId+'/1/0')
        }else{  
            this.form.topicHead = this.user.name
            this.$refs['selectProjectDialog'].show()
        }*/
      },
      goExperimentEdit(item){
          this.$refs['selectProjectDialog'].hide()
          this.$router.replace('/manager/experiment/edit/'+item.id+'/'+item.formTemplateId+'/1/0')
      },
      goToAddProject(){
        this.$refs['selectProjectDialog'].hide()
        this.$router.replace('/manager/group?create=create')
        this.$root.$emit("toAddProject")
      },
      getTobeReviewedCount(){
        GetTobeReviewedCount().then(e => {
              let data = this.APIParse(e);
              this.tobeReviewedCount = data;
          })
      }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../components/base/ui/style/var.scss';
  @import '../../components/base/ui/style/transition.scss';
  .Container--x {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
  }
  .App__root {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .AppHeader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    background: $--color-primary;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
    z-index: $--index-top;
  }

  .AppHeader__inner {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .AppHeader__menuButton {
    border: none;
    border-radius: 0;
    height: 60px;
    width: 64px;
    padding: 0;
    font-size: $--font-size-extra-large;
  }
  .AppHeader__logo {
    font-family: tahoma,arial,'Hiragino Sans GB','\5b8b\4f53',sans-serif;
    font-weight: 600;
    color:  $--color-white;
    //font-size: $--font-size-medium;
    padding: 0 10px;
    padding-right: 5px;
  }
  .AppHeader__domainTitle{
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -5px;
  }
  .AppHeader__userInfo {
    margin-left: auto;
    padding: 0 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .AppHeader__userInfoText {
    margin: 0 12px;
    color: $--color-white;
  }
  .AppMenu {
    height: 100vh;
    border: none;
    .base-icon-s-operation, .base-icon-setting, .base-icon-chat-line-round, .base-icon-brush, .base-icon-data-analysis {
      font-weight: 600;
    }
    .base-icon-s-marketing, .base-icon-s-order {
      font-size: $--font-size-extra-large;
    }
  }
  .AppMenu:not(.base-menu--collapse)  {
    width: 160px;
  }
  .Menu__control{
    position: absolute;
    bottom: 30px;
    width: 30px;
    height: 30px;
    right: -30px;
    background-color: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1000;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      box-shadow: 2px 0px 4px rgba(0, 0, 0, .2);
    }
  }
</style>
<style lang="scss">
  .AppMenu {
    overflow-y: auto;
    height: 95vh !important;
    .base-menu-item-group__title {
      display: none!important;
    }
    .base-submenu .base-menu-item {
      //padding-left: 54px!important;
      overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 160px;
  padding-right: 20px;
  font-weight: normal;
    }
    .base-menu-item.is-active{
      font-weight: 600 !important;
    }
	  .base-menu-item  .iconfont{
		  margin-right: 5px;
		  width: 24px;
		  text-align: center;
		  font-size: 18px;
		  vertical-align: middle;
		  display: inline-block;
	  }
    .base-submenu  .iconfont{
		  margin-right: 5px;
		  width: 24px;
		  text-align: center;
		  font-size: 18px;
		  vertical-align: middle;
		  display: inline-block;
	  }
    .base-submenu  .elIcon{
		  margin-right: 5px;
		  width: 24px;
		  text-align: center;
		  font-size: 18px;
		  vertical-align: middle;
		  display: inline-block;
	  }
  }
  .AppMenuPopper {
    .base-menu--popup {
      min-width: 120px;
    }
  }
  .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
.thumbnail {
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  text-align: center;
  border:1px solid #fff;

}
.thumbnail:hover{
  border:1px solid #8da7ff;
  background-color: #eef3ff;
  transform: scale(1.02);

  cursor: pointer;
}
.thumbnail p{
  margin-top: 5px;
  margin-bottom: 10px;
}
.thumbnail h4{
    line-height: 16px;
    height: 32px;
    margin: 5px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.btnInfo{
  background-color: #409eff !important;
  border-color: #409eff !important;
  color:#fff;
}
.page-warp{
    width: 100%;
    text-align: right !important;
    margin-top: 15px !important;
}
.hideOne{
	display: -webkit-box;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 1;
	  overflow: hidden;
	  /* word-break: break-all; */
	  word-break: normal;
}

.hideTwo{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	/* word-break: break-all; */
	word-break: normal;
}
.hideThree{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	word-break: break-all;
	text-indent: 2rem;
}
.hideFour{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
	word-break: break-all;
	text-indent: 2rem;
}
.expandItem{
  text-align: center;
  cursor: pointer;
  line-height: 24px;
  margin: 0px 20px;
  border-top: 1px solid #eee;
  color:#409eff;
  font-size: 20px;
}
.base-menu--vertical{
  overflow-y: auto;
  height: auto;
  max-height: 95vh;
}
.supTitle{
  color: #f56c6c;
  vertical-align: super;
  font-size: 24px;
}
.AppHeader__tool{
  margin-left: 30px;
}
.AppHeader__tool a{
  margin-right: 15px;
  color: #fff;
  font-size: 14px;
}
.AppHeader__tool a img{
  vertical-align: sub;
  width: 20px;
  margin-right: 5px;
}
.el-link.el-link--info.blueLink.is-underline:hover:after {
    border-color: #409eff;
}
</style>
