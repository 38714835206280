<template>
  <div class="home">
    <x-header>语音听写</x-header>

    <x-content
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <el-row :gutter="20" class="speechRec">
        <p style="text-align: center;">
          <el-button type="primary" v-if="!isReceivingVoice" @click="speechSynthesis"><i class="el-icon-microphone"></i> 语音输入</el-button>
          <el-button type="success" v-else @click="closeConnectIAT"><i class="el-icon-turn-off-microphone"></i> 语音关闭</el-button>
          <div style="text-align: center;"  v-if="isReceivingVoice"> <img style="height: 50px;" src="/static/img/yy.gif"/></div>
        </p>
        <template v-if="speechResult">
        <h3>识别结果</h3>
        <p class="speechResult" > <el-button
                        size="mini"
                        @click="copySpeechResult()"
                        round
                        type="success"
                        plain
                        icon="el-icon-document-copy"
                        >&nbsp;复制</el-button
                      ><span style="margin-left: 10px">{{ speechResult }}</span></p>
                      </template>
        </el-row>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
import Bus from "../../util/bus";
import { connectIAT, disconnectIAT } from "../../util/IFYRecogning";
import { copyText } from "@/util/copyText";

export default {
  components: { XCard },
  name: "ApplyUseManager",
  data() {
    return {
      speechResult: "",
      isReceivingVoice: false,
    };
  },
  filters: {},
  created() {},
  computed: {},
  mounted() {},
  methods: {
    speechSynthesis() {
      Bus.on("/ify/recogning/error", () => {
        this.$message.error("无法录音，未找到可用麦克风");
        this.closeConnectIAT();
        return;
      });
      connectIAT();
      this.isReceivingVoice = true;
      this.speechResult = "";
      Bus.on("/ify/recogn", (options) => {
        this.speechResult = options.result;
        console.log("111"+this.speechResult)
        if (options.isLast) {
          this.closeConnectIAT();
        }
      });
    },
    closeConnectIAT() {
      this.isReceivingVoice = false;
      disconnectIAT();
    },
    copySpeechResult() {
      if (!this.speechResult) {
        this.$message.warning("暂无数据，无法复制");
      } else {
        copyText(this.speechResult, this);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
<style >
.speechRec {
  padding: 20px;
}
.speechRec .speechResult {
  line-height: 30px;
}
.speechRec h3 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}
</style>