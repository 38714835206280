<template>
  <div class="home">
    <x-header>日志列表</x-header>
    <x-content
      v-if="loading"
      style="height: 100%"
      :contentStyle="{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }"
    >
      <bounce-loading></bounce-loading>
    </x-content>
    <x-content
      v-else
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="操作日志" name="tab1">
          <div class="search_warp" style="margin-left: 5px;">
            <el-row class="list-control_warp">
              <el-col :span="5">
                <el-input
                  alwaysShowClear
                  clearable
                  v-model="searchText1"
                  @keydown.enter.native="search1"
                  placeholder="请输入检索词，支持检索操作人、日志名称"
                >
                  <template slot="suffix">
                    <i
                      @click="search1"
                      style="cursor: pointer"
                      class="el-input__icon base-icon-search"
                    ></i>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="2" style="margin-left: 30px;">
                <el-select  filterable  alwaysShowClear clearable v-model="optionType1" @change="search1" placeholder="请选择日志类别">
                  <el-option :value="item.key" :label="item.key" v-for="(item,index) in optionTypeAgg1" :key="item.key"></el-option>
                </el-select>
              </el-col>
              <el-col :span="2" style="margin-left: 30px;">
                <el-select  filterable  alwaysShowClear clearable v-model="category1" @change="search1" placeholder="请选择日志状态">
                  <el-option :value="item.key" :label="item.value" v-for="(item,index) in categoryTypeAgg" :key="item.key"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
          <x-card>
            <el-table :data="datas1" stripe style="width: 100%">
              <el-table-column prop="create_user" label="操作人" width="80px"> </el-table-column>
              <el-table-column label="日志名称" prop="name" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="option_type" label="操作类别"  width="100px"></el-table-column>
              <el-table-column prop="category" label="日志状态" width="80px" >
                <template slot-scope="scope">
                  <el-tag size="mini" :type="scope.row.category=='EXCEPTION'?'danger':'success'">{{ scope.row.category=='OPERATE'?'成功':scope.row.category=='EXCEPTION'?'失败':'' }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="op_ip" label="IP地址" width="140px"></el-table-column>
              <el-table-column prop="op_browser" label="浏览器" width="80px"></el-table-column>
              <el-table-column prop="op_os" label="设备" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="create_time" label="操作时间" width="160px"> </el-table-column>
              <el-table-column width="90" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="logDetail(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                @current-change="pageChange1"
                background
                layout="total,prev, pager, next"
                unitText="条数据"
                :total="total1"
                :page-size="pageSize1"
                :current-page.sync="page1"
              ></el-pagination>
            </div>
          </x-card>
        </el-tab-pane>
        <el-tab-pane label="访问日志" name="tab2">
          <div class="search_warp">
            <el-row class="list-control_warp" style="margin-left: 5px;">
              <el-col :span="5">
                <el-input
                  alwaysShowClear
                  clearable
                  v-model="searchText2"
                  @keydown.enter.native="search2"
                  placeholder="请输入检索词，支持检索操作人、日志名称"
                >
                  <template slot="suffix">
                    <i
                      @click="search2"
                      style="cursor: pointer"
                      class="el-input__icon base-icon-search"
                    ></i>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="2" style="margin-left: 30px;">
                <el-select  filterable  alwaysShowClear clearable v-model="optionType2" @change="search2" placeholder="请选择日志类别">
                  <el-option :value="item.key" :label="item.key" v-for="(item,index) in optionTypeAgg2" :key="item.key"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
          <x-card>
            <el-table :data="datas2" stripe style="width: 100%">
              <el-table-column prop="create_user" label="操作人" width="80px"> </el-table-column>
              <el-table-column label="日志名称" prop="name" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="option_type" label="操作类别"  width="80px"></el-table-column>
              <el-table-column prop="op_ip" label="IP地址" width="140px"></el-table-column>
              <el-table-column prop="op_browser" label="浏览器" width="80px"></el-table-column>
              <el-table-column prop="op_os" label="设备" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="create_time" label="操作时间" width="160px"> </el-table-column>
              <el-table-column width="90" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="logDetail(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                @current-change="pageChange2"
                background
                layout="total,prev, pager, next"
                unitText="条数据"
                :total="total2"
                :page-size="pageSize2"
                :current-page.sync="page2"
              ></el-pagination>
            </div>
          </x-card>
        </el-tab-pane>
      </el-tabs>
    </x-content>
    <app-dialog ref="logDetailDialog" :dialogTitle="'日志详情'" originTitle staticDialog width="700px" >
            <el-descriptions title="" :column="1" > 
                <el-descriptions-item label="日志名称" v-if="formItem.name">{{ formItem.name }}  
                 <el-tag size="mini" v-if="formItem.category=='EXCEPTION'||formItem.category=='OPERATE'" :type="formItem.category=='EXCEPTION'?'danger':'success'" style="margin-left: 10px">{{formItem.category=='EXCEPTION'?'失败':'成功' }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="日志类别"  v-if="formItem.option_type">{{ formItem.option_type }}</el-descriptions-item>
                <el-descriptions-item label="操作人"  v-if="formItem.create_user">{{ formItem.create_user }}</el-descriptions-item>
                <el-descriptions-item label="操作时间"  v-if="formItem.create_time">{{ formItem.create_time }}</el-descriptions-item>
                <el-descriptions-item label="IP地址"  v-if="formItem.op_ip">{{ formItem.op_ip }}</el-descriptions-item>
                <el-descriptions-item label="地址"  v-if="formItem.op_address">{{ formItem.op_address }}</el-descriptions-item>
                <el-descriptions-item label="浏览器"  v-if="formItem.op_browser">{{ formItem.op_browser }}</el-descriptions-item>
                <el-descriptions-item label="设备"  v-if="formItem.op_os">{{ formItem.op_os }}</el-descriptions-item>
                <el-descriptions-item label="请求方式"  v-if="formItem.req_method">{{ formItem.req_method }}</el-descriptions-item>
                <el-descriptions-item label="请求地址"  v-if="formItem.method_name">{{ formItem.method_name }}</el-descriptions-item>
                <el-descriptions-item label="请求参数"  v-if="paramJson||paramJsonStr">
                  <json-view v-if="paramJson" :data="paramJson" deep="0" theme="vs-code" />
                  <template v-else>{{ paramJsonStr }}</template>
                  <!-- https://blog.csdn.net/lipenghao111/article/details/132737977 -->
                </el-descriptions-item>
                <el-descriptions-item label="返回结果"  v-if="resultJson||resultJsonStr">
                  <json-view v-if="resultJson" :data="resultJson" deep="1" theme="vs-code" />
                  <template v-else>{{ resultJsonStr }}</template>

                </el-descriptions-item>

                
                
            </el-descriptions>
        </app-dialog>
  </div>
</template>
<script>
import jsonView from 'vue-json-views'
import {List} from "../../service/devLog";
export default {
  name: "LogManager",
  components: {
    jsonView
  },
  data() {
    
    return {
      activeName: "tab1",
      loading: false,
      searchText1: "",
      optionType1:'',
      category1:'',
      datas1: [],
      page1: 1,
      pageSize1: 10,
      total1: 0,
      searchText2: "",
      optionType2:'',
      datas2: [],
      page2: 1,
      pageSize2: 10,
      total2: 0,
      formItem:{},
      paramJson:'',
      resultJson:'',
      paramJsonStr:'',
      resultJsonStr:'',
      optionTypeAgg1:[],
      optionTypeAgg2:[],
      categoryTypeAgg:[{key:'OPERATE',value:'成功'},{key:'EXCEPTION',value:'失败'}]
    };
  },
  created() {
    this.getOperateList();
    this.getVisitList();
 
  },
  computed: {

  },
  methods: {
    getOperateList() {
      this.loading = true;
      List({
        searchText: this.searchText1,
        type:0,
        optionType:this.optionType1,
        category:this.category1,
        page: this.page1,
        size: this.pageSize1,
    
      })
        .then((e) => {
          let data = this.APIParse(e);
          this.datas1 = data?.hits.map(item => {
                        return item._source;
                    });
          this.optionTypeAgg1=data?.aggs.optionTypeAgg.buckets
          this.total1 = data.total || 0;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search1() {
      this.page1 = 1;
      this.getOperateList();
    },
    pageChange1() {
      this.getOperateList();
    },
    getVisitList() {
      this.loading = true;
      List({
        searchText: this.searchText2,
        type:1,
        optionType:this.optionType2,
        page: this.page2,
        size: this.pageSize2,
    
      })
        .then((e) => {
          let data = this.APIParse(e);
          this.datas2 = data?.hits.map(item => {
                        return item._source;
                    });
          this.optionTypeAgg2=data?.aggs.optionTypeAgg.buckets
          this.total2 = data.total || 0;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search2() {
      this.page2 = 1;
      this.getVisitList();
    },
    pageChange2() {
      this.getVisitList();
    },
    logDetail(item){
      this.formItem=item
    
      if(this.formItem.param_json){
        try{
        const code = JSON.parse(this.formItem.param_json)
			  this.paramJson = code
      }catch{
      this.paramJsonStr=this.formItem.param_json
    }
      if(this.formItem.result_json){
        try{
        const code = JSON.parse(this.formItem.result_json)
			  this.resultJson = code
      }catch{
      this.resultJsonStr=this.formItem.result_json
    }
      }
    }
      this.$refs.logDetailDialog.show();

    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.content_warp {
  display: block;
}
.search_warp {
  width: 100%;
  margin: 16px 0px;
}
.page-warp {
  width: 100%;
  text-align: right;
  margin-top: 8px;
}

.save_btn_warp {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style >
.json-view-container{
  width: 100%;
}
</style>>