export const copyText = function(value, that) {
  const aux = document.createElement('input')
  aux.setAttribute('value', value)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  that.$nextTick(() => {

  that.$message.success('复制成功')
});
}

