<template>
	<div class="NoteManager__warp">
		<x-header back customBack @back="$router.push(`/manager/group/detail/${groupId}`)">记录本管理</x-header>
		<x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
			<bounce-loading></bounce-loading>
		</x-content>
		<x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
			<div class="search_warp">
				<el-row class="list-control_warp">
					<el-col :span="20">
						&nbsp;
						<el-button size="medium" type="primary" @click="addNote">
                            <span style="display: inline-flex; align-items: center;">
                                <svg fill="currentColor" viewBox="0 0 24 24" width="14" height="14" class="Icon"><path data-v-3cc980e7="" d="M4.076 16.966a4.19 4.19 0 0 1 1.05-1.76l8.568-8.569a.524.524 0 0 1 .741 0l2.928 2.927a.524.524 0 0 1 0 .74l-8.568 8.57c-.49.49-1.096.852-1.761 1.051l-3.528 1.058a.394.394 0 0 1-.49-.488l1.06-3.53zM20.558 4.83c.59.59.59 1.546 0 2.136l-1.693 1.692a.503.503 0 0 1-.712 0l-2.812-2.812a.504.504 0 0 1 0-.712l1.693-1.693a1.51 1.51 0 0 1 2.135 0l1.389 1.389z"></path></svg>
                                <span>新增记录本</span>
                            </span>
						</el-button>
					</el-col>
					<el-col :span="4">
						<el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入记录本名称搜索">
							<template slot="suffix">
								<i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
							</template>
						</el-input>
					</el-col>
				</el-row>
			</div>
			<x-card>
				<el-table
					:data="datas"
					stripe
					style="width: 100%">
					<el-table-column
						label="记录本名称">
						<template slot-scope="scope">
							<div style="display:flex;align-items:center;">
								<span style="margin-left: 10px">{{ scope.row.noteName }}</span>
								&nbsp;&nbsp;
								<el-tooltip v-if="scope.row.remarks"  effect="dark" :content="scope.row.remarks" placement="bottom">
									<span class="base-icon-info"></span>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="noteId"
						label="记录本编号">
					</el-table-column>
					<el-table-column
						width="300"
						label="操作">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" @click="previewNote(scope.row)">查看</el-button>
							<el-button type="warning" size="mini" @click="editNote(scope.row)">编辑</el-button>
							<el-button type="info" size="mini" @click="deleteNote(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="page-warp">
					<el-pagination
						@current-change="list"
						background
						layout="total,prev, pager, next"
						unitText="个记录本"
						:total="total"
						:page-size="pageSize"
						:current-page.sync="page"
					></el-pagination>
				</div>
			</x-card>
		</x-content>
		<app-dialog ref="addDialog" dialogTitle="新增记录本" originTitle staticDialog width="500px" @hide="dialogHide">
			<el-form ref="form" :model="form" :rules="formRule">
				<el-form-item prop="noteName">
					<el-input placeholder="记录本名称(必填)" v-model="form.noteName"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input placeholder="记录本描述" type="textarea" v-model="form.remarks"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" style="float: right;" @click="submit">保存</el-button>
				</el-form-item>
			</el-form>
		</app-dialog>
	</div>
</template>

<script>
	import {GroupMixins} from "@/mixins/groupIndex";
	import {Save,List,Delete} from "@/service/note";
	export default {
		name: "NoteManager",
		mixins:[GroupMixins],
		data(){
			return {
				loading:false,
				searchText:'',
				datas:[],
				page:1,
				pageSize:10,
				total:0,
				form:{
					id:'',
					noteName:'',
					groupId:'',
					remarks: ''
				},
				defaultForm:{
					id:'',
					noteName:'',
					noteId:'',
					groupId:'',
					remarks: ''
				},
				currNote:{},
				formRule:{
					noteName:[{ required: true, message: '请填写记录本名称', trigger: 'blur' }]
				},
			}
		},
		created() {
			this.search();
		},
		methods:{
			search() {
				this.page = 1;
				this.list();
			},
			previewNote(row){
				this.$router.push('/manager/experiment/'+row.id)
			},
			editNote(item){
				this.currNote = item;
				Object.keys(this.form).forEach(key => {
					this.form[key] = item[key]
				})
				this.$refs.addDialog.show();
			},
			deleteNote(item){
				this.$confirm('此操作将永久删除该记录本及实验数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					Delete(item.id).then(e => {
						let data = this.APIParse(e)
						if(data){
							this.$message.success('记录本删除成功')
							this.search();
						}else{
							this.$message.error('记录本删除失败，请重试')
						}
					}).catch(ex => {
						this.$message.error('记录本删除失败，请重试')
					})
				});
			},
			addNote(){
				this.form.groupId = this.groupId;
				this.$refs.addDialog.show()
			},
			list(){
				this.loading = true;
				List(this.searchText,this.groupId,this.page,this.pageSize).then(e => {
					let data = this.APIParse(e);
					this.datas = data?.records || []
					this.total = data?.total || 0
				}).finally(() => {
					this.loading = false;
				})
			},
			dialogHide(){
				this.$refs['form'].resetFields();
				this.$set(this,'form',JSON.parse(JSON.stringify(this.defaultForm)))
			},
			submit(){
				this.$refs.form.validate((valid) => {
					if (valid) {
						if(this.form.id){
							if(this.currNote.noteName != this.form.noteName){
								this.form.noteNameIsChange = '1'
							}else{
								this.form.noteNameIsChange = '0'
							}
						}
						Save(this.form).then(e => {
							let data = this.APIParse(e);
							if(data){
								this.$message.success('记录本保存成功');
								this.$refs['addDialog'].hide();
								this.dialogHide()
								this.search();
							}else{
								this.$message.error('记录本保存失败，请重试');
							}
						}).catch(ex => {
							this.$message.error('记录本保存失败，请重试');
						})
					} else {
						this.$message.error('记录本保存失败，请重试');
						return false;
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.NoteManager__warp{width: 100%;height: 100%;overflow: auto;}
	.search_warp{width:100%;margin: 16px 0px;}
	.page-warp{width: 100%;text-align: right;margin-top: 8px;}
</style>