<template>
  <!-- ckeditor 父元素 -->
  <div class="ck-editor__editable">
    <!-- 编辑器 -->
    <textarea :name="'ckeditorData' + suffix" class="ckeditor"> </textarea>
  </div>
</template>

<script>
import katex from "katex";

export default {
  name: "XCkediter",
  data() {
    return {
      ckEditor: null,
      suffix: "",
      ckEditorContent: "",
    };
  },
  props: {
    value: String,
  },
  created() {
    this.suffix = this.generateUUID();
  },
  mounted() {
    this.iniCtkeditor();
  },
  methods: {
    iniCtkeditor() {
      // 实例化CKEditor
      this.ckEditor = window.CKEDITOR.replace("ckeditorData" + this.suffix, {
        toolbar: "Full",
        placeholder: "请输入...",
      });
      if (this.ckEditor) {
        this.ckEditor.setData(this.value);
        this.ckEditor.on("change", () => {
          this.ckEditorContent = this.ckEditor.getData();
          this.$emit("input", this.ckEditorContent);
        });
      }

      // 监听fileUploadResponse事件
      this.ckEditor.on("fileUploadResponse", (evt) => {
        // 阻止默认响应处理
        evt.stop();

        // 获取XHR和响应数据
        const data = evt.data;
        const xhr = data.fileLoader.xhr;
        const response = xhr.responseText;

        // 假设后端返回的响应格式为：{"uploaded":1,"fileName":"image.png","url":"http://example.com/image.png"}
        try {
          const responseData = JSON.parse(response);
          if (responseData.uploaded) {
            //解析出来的latex转换显示，如果转换失败则显示图片
            if (responseData.uploaded == 2) {
              data.url = responseData.url;   

              this.$confirm('检测到图片中存在公式，是否识别转换?', '提示', {
                  confirmButtonText: '转换公式',
                  cancelButtonText: '保留图片',
                  type: 'info'
                }).then(() => {
                  data.url = responseData.url;   
                  let latexStr = null;
                    latexStr = katex.renderToString(responseData.latex, {
                      throwOnError: false,
                      output: "mathml",
                    });
                    this.ckEditor.focus() // 聚焦编辑器
                    this.ckEditor.insertHtml("<p>" + latexStr + "</p>");
                   /** const dataStr = this.value
                      ? this.value + "<p>" + latexStr + "</p>"
                      : "<p>" + latexStr + "</p>";

                    this.ckEditor.setData(dataStr);*/
                    this.ckEditorContent = this.ckEditor.getData();
                    this.$emit("input", this.ckEditorContent);
      
                    // 关闭当前对话框
                    let dialog = CKEDITOR.dialog.getCurrent();
                    if (dialog) {
                      dialog.hide();
                    }
                }).catch(action => {

     
                });
     
            } else {
              // 设置文件URL
              data.url = responseData.url;
            }
          } else {
            // 上传过程中发生错误
            evt.cancel();
          }
        } catch (error) {
          // 处理响应解析错误
          data.message = "Error parsing response: " + error.message;
          evt.cancel();
        }
      });
    },
  },
  beforeDestroy() {
    // 销毁CKEditor实例
    if (CKEDITOR.instances.editor) {
      CKEDITOR.instances.editor.destroy();
    }
  },
  generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  latexToKetex() {
    let katexStr = null;

    try {
      katexStr = katex.renderToString(responseData.latex, {
        throwOnError: false,
        output: "mathml",
      });
    } catch (e) {}
    return katexStr;
  },
};
</script>
<style >
/*编辑框最低高度*/
.ck-editor__editable {
  min-height: 300px;
}
.cke_button__ckeditor_wiris_formulaeditor_icon,
.cke_button__ckeditor_wiris_formulaeditorchemistry_icon {
  width: 70px !important;
  background-size: 100% 100% !important;
}

/**强制ckidtertoolbar不换行 */
.cke_toolbar_break {
  display: inherit !important;
}
.ck-editor__editable .cke_combo_text {
  width: 26px !important;
}
.ck-editor__editable .cke_contents {
  height: 400px !important;
}
/** .ck-editor__editable #cke_59{
    display: none  !important;

  }
  .cke_toolbar:last-child {
    display: none;
}*/
.cke_bottom {
  display: none !important;
}
#FMathEd1_ads {
  display: none !important;
}
input[type="file"]:focus-visible,
input[type="file"]:-webkit-focus-ring {
  outline: none !important;
  border-color: #fff !important;
  box-shadow: none !important;
}
.cke_dialog_background_cover{
  z-index: 1000 !important;
}
.cke_editor_ckeditorData_dialog,.cke_dialog{
  z-index: 1010 !important;
}
</style>
